@value sm-up, md-up, lg-up, xl-up from 'assets/css/variables/breakpoints.css';

.shortlistedList {
  composes: contentContainer from 'assets/css/elements/layout.css';
  padding-top: var(--space-xl);
  padding-bottom: var(--space-xl);
}

.shortlistedListInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
}

.shortlistedGridHeader {
  composes: gridHeader from 'assets/css/elements/grid.css';
}

.shortlistedSearchInputWrapper {
  composes: searchInputWrapper from 'assets/css/elements/form.css';
}

@media (lg-up) {
  .shortlistedSearchInputWrapper {
    margin-left: auto;
  }
}

.shortlistedSearchInput {
  composes: searchInput from 'assets/css/elements/form.css';
}

.shortlistedGrid {
  composes: grid from 'assets/css/elements/grid.css';
}

.shortlistedGridItem {
  text-decoration: none;
}

.shortlistedPhotoWrapper {
  composes: imageWrapper from 'assets/css/elements/grid.css';
}

.shortlistedPhoto {
  composes: image from 'assets/css/elements/grid.css';
}

.shortlistedDiscipline {
  color: var(--color-gray);
}

.shortlistedName {
  composes: title from 'assets/css/elements/grid.css';
}
