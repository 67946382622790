@value sm-up, md-up, lg-up from 'assets/css/variables/breakpoints.css';

.winnerList {
  composes: contentContainer from 'assets/css/elements/layout.css';
  padding-top: var(--space-xl);
  padding-bottom: var(--space-xl);
}

.winnerListInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
}

.winnersGridHeader {
  composes: gridHeader from 'assets/css/elements/grid.css';
}

.winnersSearchInputWrapper {
  composes: searchInputWrapper from 'assets/css/elements/form.css';
}

.winnersSearchInput {
  composes: searchInput from 'assets/css/elements/form.css';
}

.winnersGrid {
  composes: grid from 'assets/css/elements/grid.css';
}

.winnersGridItem {
  text-decoration: none;
}

.winnerPhotoWrapper {
  composes: imageWrapper from 'assets/css/elements/grid.css';
}

.winnerPhoto {
  composes: image from 'assets/css/elements/grid.css';
}

.winnerDiscipline {
  color: var(--color-gray);
}

.winnerName {
  composes: title from 'assets/css/elements/grid.css';
}
