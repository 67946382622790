@value lg-up from 'assets/css/variables/breakpoints.css';

:root {
  --content-max-width: 80rem;

  --page-gutter: 2rem;

  --space-xxxs: 0.25rem;
  --space-xxs: 0.5rem;
  --space-xs: 0.7rem;
  --space-sm: 1rem;
  --space-md: 1.5rem;
  --space-lg: 2.1rem;
  --space-xl: 3rem;
  --space-xxl: 4rem;
  --space-xxxl: 7rem;
}

@media (lg-up) {
  :root {
    --space-xxxs: 0.5rem;
    --space-xxs: 0.75rem;
    --space-xs: 1rem;
    --space-sm: 1.5rem;
    --space-md: 2rem;
    --space-lg: 3.5rem;
    --space-xl: 4.5rem;
    --space-xxl: 6.5rem;
    --space-xxxl: 13rem;
  }
}
