@value md-up, lg-up, lg-down from 'assets/css/variables/breakpoints.css';

.pageHero {
  composes: contentContainer from 'assets/css/elements/layout.css';
  padding-top: var(--space-xxl);
  padding-bottom: var(--space-md);
}

@media (lg-up) {
  .pageHero {
    padding-left: 5rem;
  }
}

.pageHeroNoTitle {
  padding-top: var(--space-xxxl);
  padding-bottom: var(--space-xxxl);
}

.backgroundImage {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.pageHeroInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  max-width: 90rem;
  margin-top: 5rem;
  margin-bottom: 4rem;
}

@media (md-up) {
  .pageHeroInner {
    width: 100%;
  }
}

@media (lg-up) {
  .pageHeroInner {
    margin-top: 7rem;
    margin-bottom: 5rem;
  }
}

.heading {
  display: block;
  font-size: 1rem;
}

@media (lg-down) {
  .heading {
    text-align: center;
  }
}

.titleImage {
  max-height: 3rem;
  width: auto;
}

@media (lg-up) {
  .titleImage {
    max-height: 6.25rem;
  }
}

.screenReaderTitle {
  composes: visuallyHidden from 'assets/css/elements/visuallyHidden.css';
}
