@value md-up from 'assets/css/variables/breakpoints.css';

:root {
  --text-xs: 0.75rem;
  --text-sm: 0.875rem;
  --text-md: 1rem;
  --text-lg: 1.2rem;
  --text-xl: 1.5rem;
  --text-xl-plus: 2rem;
  --text-xxl: 2.25rem;
}

@media (md-up) {
  :root {
    --text-xs: 0.75rem;
    --text-sm: 0.875rem;
    --text-md: 1rem;
    --text-lg: 1.5rem;
    --text-xl: 2rem;
    --text-xl-plus: 2.5rem;
    --text-xxl: 4rem;
  }
}
