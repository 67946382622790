.link,
.linkUnderlineHover {
  text-decoration: none;
}

.linkUnderline {
  text-decoration: underline;
}

.linkUnderlineHover:where(:hover, :focus) {
  text-decoration: underline;
}
