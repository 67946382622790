@value sm-up, lg-up from 'assets/css/variables/breakpoints.css';

.pastEvents {
  composes: contentContainer from 'assets/css/elements/layout.css';
  padding-top: var(--space-xxl);
  padding-bottom: var(--space-xxl);
}

.pastEventsInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  display: grid;
  grid-template-areas:
    'introleft'
    'introright'
    'events';
  grid-template-columns: 1fr;
  column-gap: var(--space-md);
  row-gap: var(--space-xs);
  max-width: 60rem;
}

@media (lg-up) {
  .pastEventsInner {
    grid-template-areas:
      'introleft  introright'
      'events     events';
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: auto 1fr;
  }
}

.heading {
  composes: heading2 from 'assets/css/elements/typography.css';
  grid-area: title;
}

.blurb {
  composes: markdown from 'assets/css/elements/markdown.css';
  max-width: 50ch;
}

.blurbLeft:not(:first-child) {
  margin-top: var(--space-lg);
}

@media (lg-up) {
  .blurbLeft:not(:first-child) {
    margin-top: var(--space-xxs);
  }
}

.introLeft {
  grid-area: introleft;
}

.introRight {
  grid-area: introright;
}

.eventsGrid {
  grid-area: events;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: var(--space-md);
  row-gap: var(--space-xl);
  margin-top: var(--space-lg);
  justify-self: center;
}

@media (sm-up) {
  .eventsGrid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (lg-up) {
  .eventsGrid {
    max-width: none;
    justify-self: start;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: var(--space-lg);
  }
}

.noEvents {
  padding-top: var(--space-lg);
  padding-bottom: var(--space-lg);
  font-style: italic;
}
