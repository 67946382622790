.heading1 {
  font-family: var(--font-family-title);
  font-size: var(--text-xxl);
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
}

.heading1Small {
  composes: heading1;
  font-size: var(--text-xl-plus);
}

.heading2 {
  composes: heading1;
  font-size: var(--text-xl);
}

.heading2Small {
  composes: heading1;
  font-size: var(--text-lg);
}

.heading2Sub {
  composes: heading2;
  color: var(--color-gray);
}

.heading3 {
  font-family: var(--font-family-body);
  font-size: var(--text-md);
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2;
}

.heading3Large {
  composes: heading3;
  font-size: var(--text-lg);
  letter-spacing: 0.05em;
}

.heading3Sub {
  composes: heading3;
  font-weight: 600;
  color: var(--color-cc-grey-secondary);
}

.heading3LargeSub {
  composes: heading3Large;
  composes: heading3Sub;
  letter-spacing: 0;
}

.heading4 {
  composes: heading3;
  font-weight: 400;
}

.heading4Sub {
  composes: heading4;
  color: var(--color-gray);
}
