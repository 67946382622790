@value md-up, lg-up from 'assets/css/variables/breakpoints.css';

.eventCard {
  display: grid;
  grid-template-areas:
    'image'
    'details';
  grid-template-rows: auto 1fr;
}

.eventCardPast {
  gap: var(--space-xs);
}

.eventCardUpcoming {
  grid-template-rows: auto 1fr;
}

@media (lg-up) {
  .eventCardPast {
    grid-template-areas: 'image details';
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .eventCardUpcoming {
    grid-template-areas:
      'image'
      'details';
    grid-template-columns: 1fr;
  }
}

.imageWrapper {
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.image {
  grid-area: image;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.details {
  grid-area: details;
  display: grid;
  grid-template-areas:
    'title'
    'blurb'
    'more'
    'book';
  gap: var(--space-xs);
  align-content: start;
}

.detailsPast {
  grid-template-areas:
    'title'
    'blurb'
    'more';
}

.detailsUpcoming {
  border-style: solid;
  border-color: var(--color-black);
  border-width: 0 2px 2px 2px;
  padding: var(--space-xs) var(--space-xs) var(--space-sm) var(--space-xs);
}

.titleWrapper {
  grid-area: title;
}

.title {
  composes: heading3 from 'assets/css/elements/typography.css';
}

.date {
  composes: heading3Sub from 'assets/css/elements/typography.css';
}

.blurb {
  composes: markdown from 'assets/css/elements/markdown.css';
  grid-area: blurb;
  font-size: 0.75rem;
  line-height: 1.2;
}

.moreLinkWrapper {
  grid-area: more;
}

.moreLink {
  font-weight: 500;
  font-size: 0.75rem;
}

.bookLinkWrapper {
  grid-area: book;
  display: flex;
  align-items: flex-end;
  margin-top: var(--space-xxs);
}

.bookLink {
  composes: buttonPrimary from 'assets/css/elements/form.css';
  composes: buttonWide from 'assets/css/elements/form.css';
  margin-left: auto;
  margin-right: auto;
}
