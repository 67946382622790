@value sm-up, md-up, md-down, lg-up, lg-down, xl-up from 'assets/css/variables/breakpoints.css';

.membershipBenefits {
  composes: contentContainer from 'assets/css/elements/layout.css';
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-xxl);
}

.membershipBenefitsInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  max-width: 70rem;
}

@media (md-up) {
  .membershipBenefitsInner {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
}

.membershipBenefitsDescription {
  position: relative;
  z-index: 1;
  grid-column: 1/7;
  grid-row: 1;
}

@media (xl-up) {
  .membershipBenefitsDescription {
    grid-column: 1/6;
  }
}

.membershipPackages {
  grid-column: 8/-1;
  grid-row: 1/3;
}

@media (md-up) {
  .membershipPackages {
    max-width: 390px;
    margin-left: auto;
  }
}

.benefitsContainer {
  grid-column: 1/7;
  grid-row: 2;
  margin-top: var(--space-xxl);
}

@media (md-up) {
  .membershipPackagesInner {
    position: sticky;
    top: var(--space-lg);
  }
}

.label {
  composes: label from 'assets/css/elements/form.css';
}

.price {
  margin-top: var(--space-lg);
  font-size: 2.5rem;
  font-weight: 600;
}

.inputGroup {
  composes: group from 'assets/css/elements/form.css';
}

.quantityInput {
  composes: input from 'assets/css/elements/form.css';
}

.applyButtonGroup {
  margin-top: 3rem;
}

.applyButton {
  composes: buttonPrimary from 'assets/css/elements/form.css';
}

.backgroundImage {
  position: absolute;
  left: -30%;
  top: 0;
  z-index: -1;
}

.title {
  composes: heading1 from 'assets/css/elements/typography.css';
  margin-bottom: var(--space-lg);
}

@media (md-down) {
  .description {
    margin-bottom: var(--space-xl);
  }
}

.subtitle {
  composes: heading2 from 'assets/css/elements/typography.css';
  line-height: 1.2;
  text-transform: none;
  margin-bottom: var(--space-lg);
}

@media (lg-up) {
  .subtitle {
    font-size: var(--text-xl);
  }
}

.benefits {
  display: flex;
  grid-gap: 2rem;
  flex-wrap: wrap;
}

@media (lg-up) {
  .benefits {
    grid-gap: 5rem;
  }
}

.benefit {
  text-align: left;
  cursor: initial;
  color: var(--color-black);
}

@media (lg-up) {
  .benefit {
    height: 15rem;
    width: 15rem;
    position: relative;
    cursor: pointer;
  }
}

.benefitInner {
  font-family: var(--font-family-body);
}

@media (lg-up) {
  .benefitInner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 3.5rem 2rem 2rem 3.25rem;
    z-index: 1;
    color: var(--color-white);
    font-family: var(--font-family-title);
  }
}

.benefit:where(:focus) {
  outline: none;
}

.benefit:where(:hover, :focus) .benefitBackgroundImage {
  filter: none;
}

@media (lg-up) {
  .benefit:where(:hover, :focus) .benefitDescription {
    display: block;
  }
  .benefit:where(:hover, :focus) .benefitTitle {
    display: none;
  }
}

.benefitBackgroundImage {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: contain;
  filter: brightness(0) saturate(100%);
  transition: 0.2s filter;
}

@media (lg-down) {
  .benefitBackgroundImage {
    display: none;
  }
}

.benefitIndex {
  composes: heading2 from 'assets/css/elements/typography.css';
  margin-bottom: 0.5rem;
  color: var(--color-white);
}

@media (lg-down) {
  .benefitIndex {
    display: none;
  }
}

.benefitTitle {
  composes: heading2Small from 'assets/css/elements/typography.css';
  color: var(--color-cc-red);
}

@media (lg-up) {
  .benefitTitle {
    color: var(--color-white);
  }
}

.benefitDescription {
  font-size: 1rem;
}

@media (lg-up) {
  .benefitDescription {
    display: none;
    font-family: var(--font-family-title);
    font-size: 0.9rem;
    color: var(--color-white);
    text-transform: uppercase;
  }
}

@media (lg-down) {
  .benefitDescription {
    margin-top: 0.75rem;
  }
}
