@value md-up, lg-up from 'assets/css/variables/breakpoints.css';

.joinCommunity {
  composes: contentContainer from 'assets/css/elements/layout.css';
  background-color: var(--color-black);
  color: var(--color-white);
  padding-top: var(--space-xxl);
  padding-bottom: var(--space-xxl);
}

.joinCommunityInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  max-width: 30rem;
}

@media (md-up) {
  .joinCommunityInner {
    max-width: 40rem;
  }
}

@media (lg-up) {
  .joinCommunityInner {
    max-width: 60rem;
  }
}

.titleWrapper {
  display: block;
}

.title {
  composes: heading1 from 'assets/css/elements/typography.css';
}

.list {
  --link-padding-y: var(--space-sm);
  --link-padding-left: var(--space-sm);
  display: block;
  padding-top: var(--space-xl);
  margin-top: calc(var(--link-padding-y) * -1);
  margin-bottom: calc(var(--link-padding-y) * -1);
}

.customerItemLink {
  --checkbox-size: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  font-size: 1rem;
  text-transform: uppercase;
  line-height: 1;
  cursor: pointer;
  padding-top: var(--link-padding-y);
  padding-bottom: var(--link-padding-y);
}

@media (md-up) {
  .customerItemLink {
    --checkbox-size: 4rem;
    padding-left: var(--link-padding-left);
    margin-left: calc(var(--link-padding-left) * -1);
  }
}

@media (md-up) {
  .customerItemLink {
    font-size: 1.5rem;
  }
}

.customerItemLink:before {
  content: '';
  display: block;
  width: var(--checkbox-size);
  height: var(--checkbox-size);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-right: var(--space-md);
  border: 2px solid var(--color-white);
}

.customerItemLink:after {
  content: '';
  display: block;
  position: absolute;
  left: calc(var(--checkbox-size) * -0.5 + var(--link-padding-left));
  top: calc(var(--checkbox-size) * -0.5 + var(--link-padding-y));
  width: calc(var(--checkbox-size) * 2);
  height: calc(var(--checkbox-size) * 2);
  background-image: url('assets/img/join-paint.png');
  background-repeat: no-repeat;
  background-size: contain;
  pointer-events: none;
  opacity: 0;
  transform: scale(1.05);
  transition: all 250ms ease;
  filter: brightness(0) saturate(100%) invert(27%) sepia(39%) saturate(5682%)
    hue-rotate(294deg) brightness(96%) contrast(114%);
}

.customerItem:nth-child(2) .customerItemLink:after {
  filter: brightness(0) saturate(100%) invert(54%) sepia(27%) saturate(4405%)
    hue-rotate(360deg) brightness(101%) contrast(100%);
}

.customerItem:nth-child(3) .customerItemLink:after {
  filter: brightness(0) saturate(100%) invert(11%) sepia(94%) saturate(5227%)
    hue-rotate(358deg) brightness(94%) contrast(115%);
}

.customerItemLink:hover:after,
.customerItemLink:focus:after {
  opacity: 1;
  transform: scale(1);
}

.outlinedText {
  color: transparent;
  -webkit-text-stroke: 1px var(--color-white);
}

/* Animation */
@media (lg-up) {
  .titleWrapper,
  .customerItem {
    transform: translateY(50px);
  }

  .customerItem.rollUp:nth-child(1) {
    animation-delay: 0.2s;
  }

  .customerItem.rollUp:nth-child(2) {
    animation-delay: 0.4s;
  }

  .customerItem.rollUp:nth-child(3) {
    animation-delay: 0.6s;
  }
}
.rollUp {
  composes: rollUp from 'assets/css/elements/animation.css';
}
