@value sm-up, md-up, md-down, lg-up, lg-down, xl-up from 'assets/css/variables/breakpoints.css';

.donationBlack {
  composes: contentContainer from 'assets/css/elements/layout.css';
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-xxl);
}

.donationBlackInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  max-width: 68rem;
}

@media (md-up) {
  .donationBlackInner {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
}

.contentWrapper {
  grid-column: 1 / 6;
}

.selector {
  grid-column: 6 / -1;
}

@media (md-down) {
  .selectorInner {
    margin-top: var(--space-lg);
  }
}

@media (md-up) {
  .selectorInner {
    position: sticky;
    top: var(--space-lg);
    max-width: 390px;
    margin-left: auto;
  }
}

.titleWrapper {
  position: relative;
}

.title {
  composes: heading1 from 'assets/css/elements/typography.css';
  display: inline-block;
}

.titleBackground {
  position: absolute;
  left: -1rem;
  bottom: -1rem;
  width: 15rem;
  z-index: -1;
}

.description {
  margin-top: var(--space-md);
  white-space: pre-line;
}

.label {
  composes: label from 'assets/css/elements/form.css';
}

.buttonWrapper {
  margin-top: var(--space-lg);
}

.button {
  composes: buttonPrimary from 'assets/css/elements/form.css';
}
