@value md-up, lg-up, lg-down from 'assets/css/variables/breakpoints.css';

.featuredNews {
  composes: contentContainer from 'assets/css/elements/layout.css';
  padding-top: var(--space-xl);
  padding-bottom: var(--space-xl);
  overflow: hidden;
}

.featuredNewsInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
}

.title {
  composes: heading1 from 'assets/css/elements/typography.css';
  display: inline-block;
  position: relative;
  margin-bottom: var(--space-xl);
}

.title::after {
  content: '';
  position: absolute;
  width: 140%;
  height: 275%;
  left: -20%;
  top: -65%;
  z-index: -1;
  background-image: url('assets/img/featured-news-title-paint.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.carousel {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  margin-left: calc(var(--page-gutter) * -1);
  margin-right: calc(var(--page-gutter) * -1);
}

@media (lg-up) {
  .carousel {
    padding-left: var(--space-xxxl);
    overflow: visible;
  }
}

@media (lg-down) {
  .carousel {
    --carouselItemWidth: 20rem;
    --firstLastItemsOffset: calc((100vw - var(--carouselItemWidth)) / 2);
  }
}

.carousel::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url('assets/img/featured-news-carousel-paint.png');
  background-repeat: no-repeat;
  background-size: 15% auto;
  background-position: 15% 60%;
}

.carouselItem {
  scroll-snap-align: center;
}

.carouselItem:not(:last-child) {
  margin-right: var(--space-sm);
}

@media (lg-down) {
  .carouselItem:not(:last-child) {
    margin-right: 1rem;
  }
  .carouselItem:first-child {
    margin-left: var(--firstLastItemsOffset);
  }
  .carouselItem:last-child {
    margin-right: var(--firstLastItemsOffset);
  }
}

@media (lg-up) {
  .carouselItem:nth-child(1) {
    margin-top: 20%;
  }

  .carouselItem:nth-child(2) {
    margin-top: 5%;
  }

  .carouselItem:nth-child(3) {
    margin-top: 12.5%;
  }
}

.articleLink {
  position: relative;
  display: flex;
  width: 20rem;
  height: 26rem;
  z-index: 1;
  text-decoration: none;
}
@media (lg-down) {
  .articleLink {
    width: var(--carouselItemWidth);
  }
}

.articleImage {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (lg-down) {
  .articleImage {
    transform: scale(0.8);
    transform-origin: center;
    transition: 0.3s transform;
  }
  .carouselItemActive .articleImage {
    transform: scale(1);
  }
}

.backgroundOverlay::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: var(--color-cc-orange-60);
  z-index: -1;
}

.articleLinkContent {
  composes: backgroundOverlay;
  --elementPadding: var(--space-sm);

  position: relative;
  display: flex;
  width: 100%;
  padding: var(--elementPadding);
  color: var(--color-white);
  opacity: 0;
  transition: 0.2s opacity;
  z-index: 1;
}

@media (lg-down) {
  .articleLinkContent {
    transition: 0.2s opacity;
    transition-delay: 0.2s;
  }
  .carouselItemActive .articleLinkContent {
    opacity: 1;
  }
}

.articleLinkContent::before {
  display: none;
}

@media (lg-up) {
  .articleLinkContent::before {
    display: block;
  }
}

.articleLinkContentInner {
  display: flex;
  width: 100%;
  flex-direction: column;
  z-index: 1;
}

.articleMeta {
  line-height: 1.2;
}

.articleTitle {
  composes: heading2 from 'assets/css/elements/typography.css';
  font-size: var(--text-lg);
  margin-top: var(--space-xs);
}

.allItemsLink {
  line-height: 1;
  opacity: 0;
  transition: 0.2s opacity;
}

@media (lg-down) {
  .allItemsLink {
    display: inline-block;
    margin-top: 2rem;
    width: 100%;
    border-radius: 0;
    font-size: 1.2rem;
    padding: 1rem;
    text-transform: none;
    background-color: var(--color-cc-orange);
    text-align: center;
    color: var(--color-white);
    text-decoration: none;
    transition: 0.3s opacity;
    transition-delay: 0.2s;
    opacity: 0;
  }

  .carouselItemActive .allItemsLink {
    opacity: 1;
  }
}

@media (lg-up) {
  .allItemsLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: var(--space-xs);
  }

  .carouselItem:focus-within :where(.articleLinkContent, .allItemsLink) {
    opacity: 1;
  }

  .articleLink:where(:hover, :focus) .articleLinkContent,
  .articleLink:where(:hover, :focus) + .allItemsLink {
    opacity: 1;
  }

  .carouselItem:where(:hover, :focus)
    :where(.articleLinkContent, .allItemsLink) {
    opacity: 1;
  }
}

@media (lg-up) {
  .allItemsLink:after {
    content: '';
    display: block;
    width: 2rem;
    height: 0.7rem;
    margin-left: var(--space-sm);
    background-image: url('assets/svg/arrow.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.itemCategoryWrapper {
  text-align: right;
}

.itemContentWrapper {
  composes: backgroundOverlay;
  position: relative;
  margin: calc(var(--elementPadding) * -1);
  margin-top: auto;
  padding: var(--elementPadding);
}

@media (lg-up) {
  .itemContentWrapper:before {
    display: none;
  }
}

/* Animation */
@media (lg-up) {
  .title,
  .carouselItem {
    transform: translateY(50px);
  }

  .carouselItem.rollUp:nth-child(1) {
    animation-delay: 0.15s;
  }

  .carouselItem.rollUp:nth-child(2) {
    animation-delay: 0.25s;
  }

  .carouselItem.rollUp:nth-child(3) {
    animation-delay: 0.35s;
  }

  .carouselItem.rollUp:nth-child(4) {
    animation-delay: 0.45s;
  }
}
.rollUp {
  composes: rollUp from 'assets/css/elements/animation.css';
}
