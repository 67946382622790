@value sm-up from 'assets/css/variables/breakpoints.css';

.membershipDetails {
  composes: contentContainer from 'assets/css/elements/layout.css';
  padding-top: var(--space-xxxl);
  padding-bottom: var(--space-xxl);
}

.membershipDetailsInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  max-width: 51rem;
  overflow: visible;
}

.header {
  position: relative;
  margin-bottom: 7rem;
}

.title {
  composes: heading1 from 'assets/css/elements/typography.css';
}

.subtitle {
  composes: heading3Large from 'assets/css/elements/typography.css';
  text-transform: none;
  font-weight: 600;
  margin-top: 2rem;
}

.paintDot {
  position: absolute;
  left: -3rem;
  top: -2rem;
  z-index: -1;
}

.inputsBlock {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.label {
  composes: label from 'assets/css/elements/form.css';
}

.input {
  composes: input from 'assets/css/elements/form.css';
}

.textarea {
  composes: textarea from 'assets/css/elements/form.css';
}

.tableWrapper {
  overflow-x: auto;
  padding-bottom: 2rem;
}

.table {
  display: flex;
  flex-wrap: wrap;
  min-width: 40rem;
}

.column {
  box-sizing: border-box;
  flex-grow: 1;
  width: 28.666%;
  padding: 1.5rem;
  overflow: hidden;
}

.columnSmall {
  width: 7%;
}

.columnWhite {
  background: var(--color-white);
  color: var(--color-gray);
}

.columGray {
  background: var(--color-gray-light);
  color: var(--color-black);
}

.columnHeader {
  composes: column columnWhite;
  text-transform: uppercase;
  font-weight: 600;
}

.columnHeaderIndex {
  composes: column columnSmall columnWhite;
}

.columnHeaderDeleteButton {
  composes: column columnSmall columnWhite;
}

.columnContactIndex {
  composes: column columnSmall columGray;
}

.columnContact {
  composes: column columGray;
}

.columnContactDeleteButton {
  composes: column columnSmall columGray;
}

.newContactTable {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
}

.newContactColumn {
  composes: column;
  width: 100%;
  padding: 0.5rem;
  background: var(--color-white);
}

.newContactGroup {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

@media (sm-up) {
  .newContactColumn {
    width: 50%;
  }
}

.addContactButton {
  composes: buttonSecondary from 'assets/css/elements/form.css';
  margin-top: 2rem;
}

.submitButton {
  composes: buttonPrimary from 'assets/css/elements/form.css';
}

.removeContactButton {
  border: 0;
  padding: 0;
  font-size: 1.5rem;
  line-height: 1;
}

.logoContainer {
  display: block;
}

.logoWrapper {
  width: 30%;
  margin-bottom: 2rem;
}

.logoDocumentWrapper {
  position: relative;
}

.logoDocument {
  width: 100%;
  padding-top: 100%;
  height: 0;
  background-color: var(--color-gray-light);
}

.logoDocumentName {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
}

.attachLogoButton {
  composes: buttonSecondary from 'assets/css/elements/form.css';
}

.orderNotes {
  composes: label;
  text-align: center;
}
