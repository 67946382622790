.markdown > *:first-child {
  margin-top: 0;
}

.markdown > *:last-child {
  margin-bottom: 0;
}

.markdown :where(a) {
  color: var(--color-cc-red);
}

.markdown :where(blockquote) {
  margin-left: 0;
  padding-left: 2rem;
  border-left: 0.25rem solid var(--color-cc-red);
  font-style: italic;
}

.markdown :where(ul) {
  list-style: disc;
  padding-left: 2rem;
}

.markdown :where(li:not(first-child)) {
  margin-top: 0.5rem;
}
