.group label {
  display: flex;
  flex-direction: column;
}

.label {
  composes: label from 'assets/css/elements/form.css';
}

.input {
  composes: input from 'assets/css/elements/form.css';
}
