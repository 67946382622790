@value lg-up from 'assets/css/variables/breakpoints.css';

.linkListColumns {
  composes: contentContainer from 'assets/css/elements/layout.css';
  padding-top: var(--space-xxl);
  padding-bottom: var(--space-xxl);
}

.linkListColumnsInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
}

.heading {
  composes: heading1 from 'assets/css/elements/typography.css';
  margin-bottom: 4rem;
}

.columnsWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.column {
  width: 100%;
  text-align: center;
}

.column:not(:first-child) {
  margin-top: 4rem;
}

@media (lg-up) {
  .column {
    width: calc(33.333333% - 2rem);
    text-align: left;
  }

  .column:not(:first-child) {
    margin-top: 0;
  }
}

.columnHeading {
  composes: heading2 from 'assets/css/elements/typography.css';
}

.columnList {
  display: block;
  margin-top: 1.5rem;
}

.listItem {
  composes: heading4Sub from 'assets/css/elements/typography.css';
  display: block;
}

.listItem:not(:first-child) {
  margin-top: 1.5rem;
}
