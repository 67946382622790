@value sm-up, md-up, md-down, lg-up, lg-down, xl-up from 'assets/css/variables/breakpoints.css';

.donationBlack {
  composes: contentContainer from 'assets/css/elements/layout.css';
  padding-top: var(--space-xxl);
  padding-bottom: var(--space-xxl);
  background-color: var(--color-black);
  color: var(--color-white);
}

.donationBlackInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  max-width: 72rem;
}

@media (md-up) {
  .donationBlackInner {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
}

.contentWrapper {
  grid-column: 1 / 6;
}

.selector {
  grid-column: 6 / -1;
}

@media (md-up) {
  .selectorInner {
    max-width: 390px;
    margin-left: auto;
  }
}

@media (md-down) {
  .selector {
    margin-top: var(--space-lg);
  }
}

.titleImage {
  width: 200px;
}

.description {
  margin-top: var(--space-md);
  white-space: pre-line;
}

.label {
  composes: label from 'assets/css/elements/form.css';
}

.buttonWrapper {
  margin-top: var(--space-lg);
}

.button {
  composes: buttonPrimary from 'assets/css/elements/form.css';
}
