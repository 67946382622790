.styledSelect {
  position: relative;
  overflow: hidden;
}

.primary {
  border: solid 2px var(--color-black);
  width: 100%;
}

.secondary {
  border-bottom: solid 2px var(--color-black);
  max-width: 100%;
  width: 24rem;
}

.select {
  composes: heading3 from 'assets/css/elements/typography.css';
  text-transform: none;
  display: inline-block;
  width: 100%;
  height: 100%;
  background: var(--color-white);
  color: var(--color-black);
  border: none;
  outline: none;
  -webkit-appearance: none;
  cursor: pointer;
}

.option {
  border-radius: 0;
  background-color: var(--color-white);
  color: var(--color-black);
}

.selectButton {
  background-color: var(--color-white);
  right: 0;
  top: 0;
  position: absolute;
  pointer-events: none;
  width: 3rem;
  height: 100%;
}

.arrowDown {
  width: 0;
  height: 0;
  border-left: 0.4rem solid transparent;
  border-right: 0.4rem solid transparent;
  border-top: 0.4rem solid var(--color-black);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.primary .select {
  font-weight: 600;
  padding: 1rem;
}

.secondary .select {
  padding: 1rem 0rem;
}

.inverted {
  composes: primary;
  border-color: var(--color-white);
}
.inverted .select {
  background: var(--color-black);
  color: var(--color-white);
}
.inverted .selectButton {
  background-color: var(--color-black);
}
.inverted .arrowDown {
  border-top: 0.4rem solid var(--color-white);
}
.inverted .option {
  background-color: var(--color-black);
  color: var(--color-white);
}
