@value sm-up, md-up, lg-up, lg-down from 'assets/css/variables/breakpoints.css';

.articleList {
  composes: contentContainer from 'assets/css/elements/layout.css';
  padding-top: var(--space-xl);
  padding-bottom: var(--space-xl);
}

.articleListInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
}

.articlesListHead {
  display: flex;
  flex-wrap: wrap;
}

@media (lg-up) {
  .articlesListHead {
    justify-content: space-between;
    align-items: flex-end;
  }
}

.categories {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
}

@media (lg-up) {
  .categories {
    width: 60%;
  }
}

.category {
  margin: 0.5rem;
}

.categoryActive {
  background-color: var(--color-cc-orange);
}

.inputGroup {
  composes: searchInputWrapper from 'assets/css/elements/form.css';
  margin-top: var(--space-md);
}

.searchInput {
  composes: searchInput from 'assets/css/elements/form.css';
}

.articles {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--space-md);
  padding-top: var(--space-md);
  min-height: 50vh;
}

@media (md-up) {
  .articles {
    grid-template-columns: 1fr 1fr;
  }
}

.article {
  position: relative;
  text-decoration: none;
}

@media (lg-up) {
  .article {
    width: 100%;
    height: 0;
    padding-top: 66%;
  }

  .article:where(:hover, :focus) .articleInner {
    opacity: 1;
  }

  .articleInner {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    transition: 0.2s opacity;
    background-color: var(--color-cc-orange-60);
  }

  .articleContent {
    display: block;
    margin-top: auto;
    color: var(--color-white);
  }

  .articleCategoryWrapper {
    text-align: right;
  }

  .articleInner {
    opacity: 0;
    padding: var(--space-md);
  }
  .articleImageWrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

@media (lg-down) {
  .articleCategoryWrapper {
    display: none;
  }
  .articleInner {
    padding: var(--space-sm) 0;
  }
  .articleImageWrapper {
    position: relative;
    width: 100%;
    padding-top: 66%;
  }
}

.articleImage {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.articleTitle {
  composes: heading2 from 'assets/css/elements/typography.css';
  max-width: 30ch;
  margin-top: var(--space-xs);
}
