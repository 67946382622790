@value sm-up, md-up, md-down, lg-up, lg-down, xl-up from 'assets/css/variables/breakpoints.css';

.awardsEntry {
  composes: contentContainer from 'assets/css/elements/layout.css';
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-xxxl);
}

.awardsEntryInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
}

@media (md-up) {
  .awardsEntryInner {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
}

.contentWrapper {
  grid-column: 1 / 6;
}

.applyWrapper {
  grid-column: 8 / -1;
}

.title {
  composes: heading1 from 'assets/css/elements/typography.css';
  grid-area: title;
}

.description {
  grid-area: description;
  margin-top: var(--space-lg);
}

.descriptionText {
  composes: markdown from 'assets/css/elements/markdown.css';
}

.descriptionList {
  composes: scribbleList from 'assets/css/elements/list.css';
}

.descriptionListItem {
  composes: scribbleListItem from 'assets/css/elements/list.css';
}

.apply {
  grid-area: apply;
}

.label {
  composes: label from 'assets/css/elements/form.css';
  margin-bottom: 0;
}

.applyButtonGroup {
  margin-top: 3rem;
}

.applyButton {
  composes: buttonPrimary from 'assets/css/elements/form.css';
}

.applyPrice {
  font-size: 2.5rem;
  font-weight: 600;
}

.applyDescription {
  margin-top: var(--space-md);
}
