@value md-up, lg-up from 'assets/css/variables/breakpoints.css';

.contentContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-right: var(--page-gutter);
  padding-left: var(--page-gutter);
  position: relative;
  z-index: 1;
}

.contentContainerInner {
  width: 100%;
  max-width: var(--content-max-width);
  margin-right: auto;
  margin-left: auto;
}

@media (md-up) {
  .contentContainerInner {
    width: 85%;
  }
}
