@value md-up from 'assets/css/variables/breakpoints.css';

.label {
  composes: heading3 from 'assets/css/elements/typography.css';
  color: var(--color-gray);
  margin-bottom: 1.2rem;
  font-weight: 600;
}

.input {
  composes: heading3 from 'assets/css/elements/typography.css';
  text-transform: none;
  font-weight: 600;
  width: 100%;
  border: 2px solid var(--color-black);
  padding: 1rem;
  color: var(--color-black);
  border-radius: 0;
}

.textarea {
  composes: input;
}

.group {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.group:first-child {
  margin-top: 0;
}

.group:last-child {
  margin-bottom: 0;
}

.button {
  display: inline-block;
  padding: 1rem 2rem;
  border-radius: 100rem;
  text-align: center;
  line-height: 1;
  font-size: 1.2rem;
  font-weight: bold;
  font-family: var(--font-family-body);
  text-transform: uppercase;
}

.button[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}

.buttonWide {
  padding-left: 3rem;
  padding-right: 3rem;
}

.buttonPrimary {
  composes: button;
  background-color: var(--color-cc-red);
  color: var(--color-white);
  border: 3px solid var(--color-cc-magenta);
  letter-spacing: 0.04em;
  text-decoration: none;
  transition: all 0.1s ease;
}

.buttonPrimary:not([disabled]):where(:hover, :focus) {
  background-color: var(--color-cc-magenta);
}

.buttonPrimary:where(:focus) {
  outline: none;
}

.buttonSecondary {
  composes: button;
  background-color: var(--color-gray-light);
  color: var(--color-black);
  transition: all 0.1s ease;
}

.buttonSecondary:not([disabled]):where(:hover, :focus) {
  background-color: var(--color-gray);
}

.buttonInput {
  composes: button;
  border: 2px solid var(--color-black);
  border-radius: 0;
  color: var(--color-black);
  letter-spacing: 0.04em;
}

.buttonLink {
  composes: button;
  color: var(--color-black);
  border: none;
  text-decoration: underline;
  font-weight: normal;
  padding: 0;
}

.searchInputWrapper {
  position: relative;
  max-width: 100%;
  width: 24rem;
}

.searchInputWrapper::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 1rem;
  height: 100%;
  background-image: url('assets/svg/search.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.searchInput {
  composes: heading3 from 'assets/css/elements/typography.css';
  text-transform: none;
  width: 100%;
  border: 0;
  border-bottom: 2px solid var(--color-black);
  padding: 1rem 2rem 1rem 0;
}

.searchInput::placeholder {
  color: var(--color-cc-grey-secondary);
}
