.articleCategory {
  display: inline-flex;
  background-color: var(--color-black);
  color: var(--color-white);
  padding: 0.5em 0.7em;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.01em;
  text-decoration: none;
  border: 1px solid transparent;
}

.articleCategory[href]:hover {
  opacity: 0.75;
}

.articleCategory:focus {
  border: 1px dashed var(--color-white);
}

.articleCategoryActive {
  cursor: default;
}

.articleCategoryActive:hover {
  opacity: 1;
}
