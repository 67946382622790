@value lg-down from 'assets/css/variables/breakpoints.css';

.sidebar {
  position: absolute;
  top: 34rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: var(--space-md);
  z-index: 10;
  color: var(--color-black);
  visibility: hidden;
}

@media (lg-down) {
  .sidebar {
    display: none;
  }
}

.sidebarTopPositionShort {
  top: 50%;
  transform: translateY(-50%);
}

.colorMagenta {
  color: var(--color-cc-magenta);
}

.socialLinks {
  margin: -0.75rem;
  list-style: none;
}

.socialLink {
  display: inline-block;
  padding: 0.75rem;
}

.socialLink svg {
  width: 1.25rem;
  height: 1.25rem;
}

.text {
  margin-top: 1rem;
  writing-mode: tb-rl;
  transform: rotate(180deg);
  line-height: 1;
  color: currentColor;
}
