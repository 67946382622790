@value md-up, lg-up from 'assets/css/variables/breakpoints.css';

.footer {
  --submit-button-width: 3rem;
  --input-button-padding: calc(var(--submit-button-width) + 1rem);
  composes: contentContainer from 'assets/css/elements/layout.css';
  background-color: var(--color-black);
  color: var(--color-white);
  padding-top: var(--space-xxl);
  padding-bottom: var(--space-xl);
}

.withNoPaddingTop {
  padding-top: 0;
}

@media (md-up) {
  .footer {
    --submit-button-width: 4rem;
    padding-top: var(--space-xxl);
  }
}

@media (lg-up) {
  .footer {
    --submit-button-width: 4.5rem;
  }
}

.footerInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  max-width: 72rem;
}

.formTitle {
  composes: heading1Small from 'assets/css/elements/typography.css';
  margin-bottom: var(--space-lg);
}

.formGroup {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
}

.formError {
  position: relative;
  width: 100%;
  height: 1.8rem;
  font-family: var(--font-family-title);
  font-size: 2rem;
  line-height: 2.5rem;
  text-transform: uppercase;
  color: var(--color-cc-red);
  margin: var(--space-xl) 0;
}

.startOver {
  display: inline-block;
  height: 1.8rem;
  font-family: var(--font-family-title);
  font-size: 2rem;
  line-height: 2.5rem;
  text-decoration: underline;
  text-transform: uppercase;
  color: var(--color-white);
}

.formError .startOver:hover {
  text-decoration: none;
}

@media (md-up) {
  .startOver {
    margin: 0 0.5rem;
  }
}

.formSuccess {
  font-size: var(--text-lg);
  font-family: var(--font-family-body);
  color: var(--color-cc-grey-secondary);
  margin: var(--space-xl) 0;
}

.input {
  composes: heading3 from 'assets/css/elements/typography.css';
  color: var(--color-white);
  padding: var(--space-sm) var(--input-button-padding) var(--space-sm) 0;
  border: 0;
  border-bottom: 2px solid currentColor;
  background-color: transparent;
  width: 100%;
}

.input::placeholder {
  color: var(--color-cc-grey);
}

.submitButton {
  width: var(--submit-button-width);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.submitArrow {
  display: block;
  width: 100%;
  height: auto;
  color: var(--color-cc-red);
}

.partners {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-xxl);
}

@media (md-up) {
  .partners {
    align-items: center;
  }
}

.partnersItems {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

@media (md-up) {
  .partnersItems {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 65%;
    padding-right: 1.5rem;
  }
}

.partnersTitleContainer {
  display: flex;
  position: relative;
  flex-grow: 1;
  z-index: 1;
  padding-top: var(--space-lg);
  padding-bottom: var(--space-lg);
  order: -1;
}

@media (md-up) {
  .partnersTitleContainer {
    order: 2;
    align-items: center;
    justify-content: center;
    width: 30%;
  }
}

@media (md-up) {
  .partnersTitleContainer::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url('assets/img/footer-arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.partner {
  flex: 1;
  text-align: center;
}

.partnerLogo {
  max-width: 80%;
}

.partnersTitle {
  text-transform: uppercase;
  font-size: 2.5rem;
  font-family: var(--font-family-title);
  line-height: 1;
}
@media (md-up) {
  .partnersTitle {
    font-size: 1.5em;
  }
}
@media (lg-up) {
  .partnersTitle {
    font-size: 2em;
  }
}

.footerCopyright {
  text-align: center;
  margin-bottom: var(--space-md);
  font-weight: bold;
}

.footerLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-width: 450px) {
  .footerLinks {
    flex-direction: row;
  }
}

.footerLinkItem:not(:last-child) {
  margin-bottom: var(--space-sm);
}

@media (min-width: 450px) {
  .footerLinkItem:not(:last-child) {
    margin-right: var(--space-sm);
    margin-bottom: 0;
  }
}
