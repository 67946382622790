@value md-up from 'assets/css/variables/breakpoints.css';

.backLink {
  composes: heading2 from 'assets/css/elements/typography.css';
  display: inline-flex;
  align-items: center;
  color: var(--color-cc-red);
  text-decoration: none;
}

.arrow {
  --arrow-transform: rotate(180deg) translateY(0.125em);

  display: block;
  width: 3rem;
  height: auto;
  margin-right: 1rem;
  transform: var(--arrow-transform);
  transition: transform 0.05s ease;
}

.label {
  display: inline-block;
}

@media (md-up) {
  .backLink:where(:hover, :focus) .arrow {
    transform: var(--arrow-transform) translateX(1rem);
  }
}
