.group label {
  display: flex;
  flex-direction: column;
}

.label {
  composes: label from 'assets/css/elements/form.css';
}

.textarea {
  composes: textarea from 'assets/css/elements/form.css';
}

.notes {
  margin-top: 2rem;
}
