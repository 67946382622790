.scribbleList {
  list-style: none;
  padding-left: 2.5em;
}

.scribbleListItem {
  position: relative;
}

.scribbleListItem::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: -2.5em;
  width: 1.5em;
  height: 1.5em;
  background-image: url('assets/svg/list-bullet-red.svg');
  background-size: 1.5em 1.5em;
  background-repeat: no-repeat;
  background-position: left center;
}
