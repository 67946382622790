:root {
  /* Creative Conscience primary colours */
  --color-cc-red-rgb: 230, 0, 0;
  --color-cc-black-rgb: 0, 0, 0;
  --color-cc-grey-rgb: 233, 232, 231;
  --color-cc-white-rgb: 255, 255, 255;

  --color-cc-red: rgba(var(--color-cc-red-rgb));
  --color-cc-black: rgba(var(--color-cc-black-rgb));
  --color-cc-grey: rgba(var(--color-cc-grey-rgb));
  --color-cc-white: rgba(var(--color-cc-white-rgb));

  /* Creative Conscience secondary colours */
  --color-cc-orange-rgb: 255, 128, 0;
  --color-cc-magenta-rgb: 249, 22, 206;
  --color-cc-grey-secondary-rgb: 168, 168, 168;
  --color-cc-grey-tertiary-rgb: 96, 96, 96;

  --color-cc-orange: rgb(var(--color-cc-orange-rgb));
  --color-cc-orange-60: rgba(var(--color-cc-orange-rgb), 0.6);
  --color-cc-magenta: rgb(var(--color-cc-magenta-rgb));
  --color-cc-grey-secondary: rgb(var(--color-cc-grey-secondary-rgb));
  --color-cc-grey-tertiary: rgb(var(--color-cc-grey-tertiary-rgb));

  /* Blacks and whites */
  --color-black: var(--color-cc-black);
  --color-white: var(--color-cc-white);
  --color-gray: var(--color-cc-grey-secondary);
  --color-gray-light: var(--color-cc-grey);
  --color-gray-dark: var(--color-cc-grey-tertiary);

  /* Brand colour aliases */

  /* Additional custom colours */
  --color-magenta-rgb: 221, 55, 99;
  --color-magenta-light-rgb: 221, 55, 99;
  --color-magenta-extralight-rgb: 221, 55, 99;
  --color-pomegranate-rgb: 199, 76, 77;
  --color-yellow-rgb: 255, 242, 84;

  --color-magenta: rgb(var(--color-magenta-rgb));
  --color-magenta-light: rgb(var(--color-magenta-light-rgb));
  --color-magenta-extralight: rgb(var(--color-magenta-extralight-rgb));
  --color-pomegranate: rgb(var(--color-pomegranate-rgb));
  --color-yellow: rgb(var(--color-yellow-rgb));

  /* Award colours */
  --color-gold-rgb: 174, 154, 100;
  --color-silver-rgb: 196, 193, 191;
  --color-bronze-rgb: 213, 183, 162;

  --color-gold: rgb(var(--color-gold-rgb));
  --color-silver: rgb(var(--color-silver-rgb));
  --color-bronze: rgb(var(--color-bronze-rgb));
}
