@value lg-up from 'assets/css/variables/breakpoints.css';

.event {
  composes: contentContainer from 'assets/css/elements/layout.css';
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-xxl);
}

.eventLoading {
  opacity: 0;
}

.eventInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  max-width: 51rem;
  display: grid;
  grid-template-areas:
    'title'
    'date'
    'details'
    'book'
    'back';
  column-gap: var(--space-lg);
  row-gap: var(--space-md);
}

@media (lg-up) {
  .eventInner {
    grid-template-areas:
      'title details'
      'date details'
      'book details'
      'back back';
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto auto 1fr auto;
  }

  .eventInnerWithoutDate {
    grid-template-areas:
      'title details'
      'book details'
      'back back';
    grid-template-rows: auto 1fr auto;
  }
}

.title {
  composes: heading1 from 'assets/css/elements/typography.css';
  font-size: var(--text-xl-plus);
  grid-area: title;
  margin-top: 0.2em;
}

.dateWrapper {
  grid-area: date;
}

.date {
  composes: heading3Sub from 'assets/css/elements/typography.css';
}

.endDateTitle {
  margin-top: 1rem;
}

.details {
  composes: markdown from 'assets/css/elements/markdown.css';
  grid-area: details;
}

.detailsList {
  composes: scribbleList from 'assets/css/elements/list.css';
}

.detailsListItem {
  composes: scribbleListItem from 'assets/css/elements/list.css';
}

.bookLinkWrapper {
  grid-area: book;
  margin-top: var(--space-md);
}

.bookLink {
  composes: buttonPrimary from 'assets/css/elements/form.css';
}

.backLinkWrapper {
  grid-area: back;
  margin-top: 4rem;
}
