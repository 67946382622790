@value md-up, lg-up from 'assets/css/variables/breakpoints.css';

.personContainer {
  composes: contentContainer from 'assets/css/elements/layout.css';
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.personContainerLoading {
  opacity: 0;
}

.personContainerInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  max-width: 51rem;
  display: grid;
  grid-template-areas:
    'details'
    'image'
    'links'
    'bio'
    'back';
  gap: 2rem;
}

@media (md-up) {
  .personContainerInner {
    grid-template-areas:
      'image details'
      'image links'
      'bio   bio'
      'back  back';
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 4rem;
  }
}

@media (lg-up) {
  .personContainerInner {
    column-gap: 6rem;
  }
}

.imageWrapper {
  grid-area: image;
  margin-left: calc(var(--page-gutter) * -1);
  margin-right: calc(var(--page-gutter) * -1);
}

@media (md-up) {
  .imageWrapper {
    margin-left: 0;
    margin-right: 0;
  }
}

.image {
  display: block;
  width: 100%;
}

.details {
  grid-area: details;
}

.detailsName {
  composes: heading2 from 'assets/css/elements/typography.css';
}

.detailsRole {
  composes: heading3LargeSub from 'assets/css/elements/typography.css';
  margin-top: 0.5rem;
}

.links {
  grid-area: links;
  align-self: end;
}

.link {
  color: var(--color-cc-red);
}

.biography {
  composes: markdown from 'assets/css/elements/markdown.css';
  grid-area: bio;
}

.biographyList {
  composes: scribbleList from 'assets/css/elements/list.css';
}

.biographyListItem {
  composes: scribbleListItem from 'assets/css/elements/list.css';
}

@media (lg-up) {
  .biography {
    margin-top: 3rem;
  }
}

.biographyLink {
  composes: linkUnderlineHover from 'assets/css/elements/link.css';
}

.backLinkWrapper {
  grid-area: back;
  margin-top: 4rem;
}
