@value md-up, lg-up, lg-down from 'assets/css/variables/breakpoints.css';

.timelineStages {
  composes: contentContainer from 'assets/css/elements/layout.css';
}

.timelineStagesInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  max-width: 60rem;
}

.stage {
  composes: stage from 'components/sections/AwardsIntro/index.module.css';
}

.stageCopy {
  composes: stageCopy from 'components/sections/AwardsIntro/index.module.css';
  padding-top: 0;
}

.stageTimeline {
  composes: stageTimeline from 'components/sections/AwardsIntro/index.module.css';
}

.stageTimeline .line,
.stageTimeline .circle,
.stageTimelineInProgress .line {
  background-color: var(--color-gray);
}

.stageTimeline .circle {
  border-color: var(--color-gray);
}

.stageTimelineCompleted .circle,
.stageTimelineCompleted .line,
.stageTimelineInProgress .circle {
  background-color: var(--color-cc-red);
}

.stageTimelineInProgress .circle,
.stageTimelineCompleted .circle {
  border-color: var(--color-cc-magenta);
}

.stageDeadline {
  composes: stageDeadline from 'components/sections/AwardsIntro/index.module.css';
}

.title {
  composes: heading2 from 'assets/css/elements/typography.css';
  margin-bottom: 0.5rem;
}

@media (lg-up) {
  .title {
    margin-bottom: var(--space-md);
  }
}

.copy {
  composes: copy from 'components/sections/AwardsIntro/index.module.css';
}

@media (lg-down) {
  .copy {
    margin-top: 2rem;
  }
}

.copy ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.copy li {
  margin: 0;
}

.copy li:not(:last-child) {
  margin-bottom: var(--space-lg);
}

.copy li a {
  display: inline-block;
  font-family: var(--font-family-title);
  font-size: var(--text-xl);
  font-weight: bold;
  text-transform: uppercase;
  max-width: 25ch;
  color: var(--color-cc-red);
  text-decoration: none;
}

/* Show first active if not hovered any, order of styles important */
.copy li:nth-child(1) a {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('assets/svg/highlight-small.svg');
}
.copy ul:hover a {
  background-image: none;
}
.copy li a:is(.active, :hover, :focus) {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('assets/svg/highlight-small.svg');
}

@media (lg-up) {
  .copy li a {
    color: var(--color-black);
  }
}

.line {
  composes: line from 'components/sections/AwardsIntro/index.module.css';
}

.stageName {
  composes: title;
}

.stageDate {
  font-family: var(--font-family-title);
  font-size: var(--text-lg);
  line-height: 1;
}

.circle {
  position: absolute;
  display: inline-block;
  left: 50%;
  width: 7rem;
  height: 7rem;
  top: -1rem;
  border-radius: 50%;
  background-color: var(--color-cc-red);
  border: 4px solid var(--color-cc-magenta);
  transform: translateX(-50%);
  z-index: 2;
}

.button {
  composes: buttonPrimary from 'assets/css/elements/form.css';
  margin-top: var(--space-md);
  color: var(--color-white);
}

@media (lg-up) {
  .lgUpHidden {
    display: none;
  }
}

@media (lg-down) {
  .lgDownHidden {
    display: none;
  }
}
