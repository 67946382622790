@value sm-up, md-up, md-down, lg-up, lg-down from 'assets/css/variables/breakpoints.css';

@media (lg-down) {
  .gridHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (lg-up) {
  .gridHeader {
    display: flex;
    flex-direction: row;
    gap: var(--space-md);
  }
  .gridHeader > * {
    flex-basis: calc(100% / 3 - var(--space-md) / 1.5);
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--space-lg) var(--space-md);
  margin-top: var(--space-lg);
}

@media (sm-up) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (lg-up) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.imageWrapper {
  width: 100%;
  height: 0;
  padding-top: 67%;
  position: relative;
}

.image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title {
  font-family: var(--font-family-title);
  font-size: var(--text-lg);
  color: var(--color-black);
  line-height: 1;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
}
