@value sm-up, md-up, lg-up from 'assets/css/variables/breakpoints.css';

.judgeList {
  composes: contentContainer from 'assets/css/elements/layout.css';
  padding-top: var(--space-xl);
  padding-bottom: var(--space-xl);
}

.judgeListInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
}

.judgesGridHeader {
  composes: gridHeader from 'assets/css/elements/grid.css';
}

.judgesGrid {
  composes: grid from 'assets/css/elements/grid.css';
}

.judgesGridItem {
  text-decoration: none;
}

.judgePhotoWrapper {
  composes: imageWrapper from 'assets/css/elements/grid.css';
}

.judgePhoto {
  composes: image from 'assets/css/elements/grid.css';
}

.judgeDiscipline {
  color: var(--color-gray);
}

.judgeName {
  composes: title from 'assets/css/elements/grid.css';
}
