@value lg-up from 'assets/css/variables/breakpoints.css';

@media (lg-up) {
  .rollUp {
    animation: 1.2s forwards;
    animation-name: rollUp;
  }

  .fadeIn {
    animation: 1.2s forwards;
    animation-name: fadeIn;
  }

  .fadeInUp {
    animation: 1.2s forwards;
    animation-name: fadeInUp;
  }

  @keyframes rollUp {
    100% {
      transform: translateY(0);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
