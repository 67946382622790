.article {
  composes: contentContainer from 'assets/css/elements/layout.css';
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-xxl);
}

.articleLoading {
  opacity: 0;
}

.articleInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  max-width: 51rem;
}

.title {
  composes: heading2 from 'assets/css/elements/typography.css';
  margin-top: var(--space-xs);
  max-width: 30ch;
}

.thumbnail {
  margin-top: var(--space-md);
  margin-bottom: var(--space-lg);
}

.markdown {
  composes: markdown from 'assets/css/elements/markdown.css';
  margin-top: var(--space-lg);
  margin-bottom: var(--space-xl);
}

.backLinkWrapper {
  margin-top: 4rem;
}
