@value sm-up, md-up, md-down, lg-up, lg-down, xl-up from 'assets/css/variables/breakpoints.css';

.richText {
  composes: contentContainer from 'assets/css/elements/layout.css';
  padding-top: var(--space-xxl);
  padding-bottom: var(--space-xxl);
}

.richTextInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  max-width: 60rem;
}

.copy {
  composes: markdown from 'assets/css/elements/markdown.css';
}

.richText h1,
.richText h2,
.richText h3,
.richText h4 {
  text-transform: uppercase;
  margin-top: var(--space-lg);
}

.richText h1 {
  font-size: 2.5rem;
}

ul,
ol {
  margin-top: var(--space-md);
  margin-bottom: var(--space-lg);
}

table {
  margin-top: var(--space-lg);
  margin-bottom: var(--space-lg);
  font-size: 0.9rem;
}

thead tr {
  background: var(--color-gray);
  text-align: left;
  line-height: 1rem;
}

td,
th {
  padding: 1rem 0.5rem;
  line-height: 1.2rem;
}

tbody td {
  background-color: var(--color-gray-light);
}
