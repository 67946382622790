#snipcart {
  font-family: var(--font-family-body);
  --bgColor-buttonPrimary: var(--color-cc-red);
  --bgColor-buttonPrimary-hover: var(--color-cc-red);
  --bgColor-buttonPrimary-focus: var(--color-cc-red);
  --bgColor-buttonPrimary-active: var(--color-cc-red);
  --shadow-buttonPrimary-hover: 0;
  --shadow-buttonPrimary-focus: 0;
  --shadow-buttonPrimary-active: 0;

  --borderColor-input: var(--color-black);
  --borderColor-input-hover: var(--color-black);
  --borderColor-input-focus: var(--color-black);
  --borderColor-input-active: var(--color-black);
  --bgColor-input-autofill: var(--color-magenta-extralight);
  --shadow-input-hover: 0;
  --shadow-input-focus: 0;
  --shadow-input-active: 0;

  --color-link: var(--color-cc-red);
  --color-link-hover: var(--color-cc-red);
  --color-link-focus: var(--color-cc-red);
  --color-link-active: var(--color-cc-red);

  --color-icon: var(--color-cc-red);

  --color-badge: var(--color-cc-red);
  --color-badge-active: var(--color-cc-red);
  --bgColor-badge-active: var(--color-white);
  --borderColor-badge: var(--color-cc-red);
  --borderColor-badge-active: var(--color-cc-red);
}

#snipcart
  :where(.snipcart__font--secondary, .snipcart__font--subtitle, .snipcart__font--subtitle-small) {
  font-family: var(--font-family-title);
}

/* Hide edit order */
#snipcart
  .snipcart-cart-summary__actions.snipcart__box--header
  .snipcart-button-link {
  display: none;
  pointer-events: none;
}

#snipcart .snipcart-base-button__label {
  text-transform: uppercase;
  font-size: var(--text-lg);
  letter-spacing: 0.04em;
  font-weight: bold;
  font-family: var(--font-family-body);
}

#snipcart .snipcart-summary-fees__total .snipcart__font--large {
  font-size: var(--text-lg);
}

#snipcart
  :where(.snipcart-input, .snipcart-form__select, .snipcart-typeahead__suggestions) {
  border: 2px solid var(--color-black);
  font-size: 1rem;
}

#snipcart :where(.snipcart-input__input, .snipcart-textbox--hidden) {
  font-family: var(--font-family-body);
}

/* Trying to change spinner styles */
/* <svg viewBox="0 0 42 41" fill="none" xmlns="http://www.w3.org/2000/svg" alt="" title="" class="snipcart__icon--large snipcart__icon"><path d="M41.5 20.497c0 11.32-9.177 20.498-20.498 20.498-11.32 0-20.497-9.177-20.497-20.498C.505 9.177 9.682 0 21.002 0S41.5 9.177 41.5 20.497zm-34.846 0c0 7.925 6.424 14.349 14.348 14.349 7.925 0 14.348-6.424 14.348-14.349 0-7.924-6.424-14.348-14.348-14.348S6.654 12.573 6.654 20.497z" fill="#E7EBED" class="bg"></path><path d="M30.49 35.11c.925 1.424.526 3.352-1.02 4.054A20.498 20.498 0 017.396 5.167c1.27-1.127 3.193-.707 4.118.718.925 1.424.49 3.309-.706 4.515a14.348 14.348 0 0015.27 23.518c1.588-.6 3.487-.232 4.412 1.192z" fill="url(#paint0_linear)" class="active"></path><defs><linearGradient id="paint0_linear" x1="23.679" y1="0" x2="23.679" y2="40.995" gradientUnits="userSpaceOnUse"><stop stop-color="#5082E4"></stop><stop offset="1" stop-color="#52BCF8"></stop></linearGradient></defs></svg> */
#snipcart .snipcart-loading-overlay__pending svg path {
  fill: var(--color-cc-red);
}

#snipcart .snipcart-summary-fees__notice {
  display: none;
}

#snipcart .snipcart-checkbox + label::before {
  border-width: 2px;
  border-radius: 0;
}
