.titleSubtitle {
  composes: contentContainer from 'assets/css/elements/layout.css';
  padding-top: var(--space-xxxl);
  padding-bottom: var(--space-xxl);
}

.titleSubtitleInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  max-width: 51rem;
  overflow: visible;
  position: relative;
}

.title {
  composes: heading1 from 'assets/css/elements/typography.css';
}

.subtitle {
  composes: heading3Large from 'assets/css/elements/typography.css';
  text-transform: none;
  font-weight: 600;
  margin-top: var(--space-md);
}

.backgrounImage {
  position: absolute;
  left: -3rem;
  top: -2rem;
  z-index: -1;
}

.discountCodes {
  margin-top: var(--space-lg);
  margin-bottom: var(--space-lg);
}

.discountCode {
  composes: subtitle;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.discountCode:not(:last-child) {
  margin-bottom: 0;
}

.buttonWrapper {
  margin-top: var(--space-md);
}

.button {
  composes: buttonPrimary from 'assets/css/elements/form.css';
}
