@value md-up, lg-up from 'assets/css/variables/breakpoints.css';

.twoColumnTitleAndText {
  composes: contentContainer from 'assets/css/elements/layout.css';
  padding-top: var(--space-xxl);
  padding-bottom: var(--space-xxl);
}

.twoColumnTitleAndTextInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  display: grid;
  grid-template-areas:
    'left'
    'right';
  grid-template-columns: 1fr;
  gap: 3rem;
}

@media (md-up) {
  .twoColumnTitleAndTextInner {
    gap: var(--space-md);
    grid-template-areas: 'left right';
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.leftColumn {
  grid-area: left;
  position: relative;
  z-index: 1;
}

.rightColumn {
  grid-area: right;
  position: relative;
  z-index: 2;
}

.titleWrapper {
  position: relative;
}

.titleBrushImage {
  color: var(--color-magenta-light);
  display: block;
  width: 10rem;
  height: auto;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: -1;
  margin-top: -2rem;
  margin-left: -2.5rem;
  pointer-events: none;
}

@media (lg-up) {
  .titleBrushImage {
    width: 18rem;
    margin-top: -3.5rem;
    margin-left: -6rem;
  }
}

.title {
  composes: heading1 from 'assets/css/elements/typography.css';
}

.text {
  composes: markdown from 'assets/css/elements/markdown.css';
  max-width: 40ch;
}

@media (lg-up) {
  .text {
    margin-top: 0;
  }
}
