@value sm-up from 'assets/css/variables/breakpoints.css';

.homeHero {
  position: relative;
  height: calc(100vw / 1.7777);
  min-height: 300px;
  max-height: 100vh;
  width: 100%;
  overflow: hidden;
}

.video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contentContainer {
  composes: contentContainer from 'assets/css/elements/layout.css';
  position: relative;
}

.contentContainerInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
}

.muteButton {
  display: block;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  background-color: #fff;
  z-index: 10;
  padding: 0.25rem;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--color-black);
}

.muteIcon {
  display: block;
  height: 1.75rem;
  width: auto;
}
