@value sm-up, lg-up, xl-up from 'assets/css/variables/breakpoints.css';

.upcomingEvents {
  composes: contentContainer from 'assets/css/elements/layout.css';
}

.upcomingEventsInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  max-width: 60rem;
}

.heading {
  composes: heading2 from 'assets/css/elements/typography.css';
}

.eventsGrid {
  grid-area: events;
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--space-md);
}

* + .eventsGrid {
  margin-top: var(--space-lg);
}

@media (sm-up) {
  .eventsGrid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (lg-up) {
  .eventsGrid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (xl-up) {
  .eventsGrid {
    gap: var(--space-lg);
  }
}

.noEvents {
  padding-top: var(--space-lg);
  padding-bottom: var(--space-lg);
  font-style: italic;
}
