@value lg-down, lg-up from 'assets/css/variables/breakpoints.css';

.project {
  composes: contentContainer from 'assets/css/elements/layout.css';
  margin-top: var(--space-xxl);
  margin-bottom: var(--space-xxl);
}

.projectLoading {
  opacity: 0;
}

.projectInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  display: grid;
  grid-template-areas:
    'details'
    'media'
    'description'
    'back';
}

@media (lg-up) {
  .projectInner {
    grid-template-areas:
      'details description'
      'media   media'
      'back    back';
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.details {
  grid-area: details;
}

.author {
  composes: heading1 from 'assets/css/elements/typography.css';
  margin-top: 0.5rem;
}

.awarded {
  composes: heading1 from 'assets/css/elements/typography.css';
  color: var(--color-cc-red);
  margin-top: 0.5rem;
}

.awardedGold {
  color: var(--color-gold);
}

.awardedSilver {
  color: var(--color-silver);
}

.awardedBronze {
  color: var(--color-bronze);
}

.discipline,
.theme,
.yearOfStudy,
.placeOfStudy,
.websites {
  margin-top: 1.5rem;
}

.disciplineTitle,
.themeTitle,
.placeOfStudyTitle,
.yearOfStudyTitle {
  font-weight: 600;
}

.websiteLink {
  color: var(--color-cc-red);
}

.media {
  grid-area: media;
  display: grid;
  gap: 2rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
  align-items: center;
}

@media (lg-down) {
  .media {
    overflow-x: auto;
    grid-auto-flow: row;
    margin: 2rem calc(var(--page-gutter) * -1);
    padding: var(--page-gutter);
  }
}

@media (lg-up) {
  .media {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 4rem;
    margin-top: 8rem;
  }
}

@media (lg-down) {
  .mediaItem {
    width: 100%;
  }
}

.description {
  composes: markdown from 'assets/css/elements/markdown.css';
  grid-area: description;
}

.description :where(a) {
  color: var(--color-cc-red);
}

.backLinkWrapper {
  grid-area: back;
  margin-top: 4rem;
}
