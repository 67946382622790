.group label {
  display: flex;
  flex-direction: column;
}

.label {
  composes: label from 'assets/css/elements/form.css';
}

.inner {
  position: relative;
}

.input {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0;
}

.button {
  composes: buttonSecondary from 'assets/css/elements/form.css';
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.previews {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 1rem;
}

.preview {
  position: relative;
  flex: 1;
  max-width: 300px;
  border-radius: 2rem;
  border: 1px solid #ddd;
  overflow: hidden;
}

.previewInner {
  padding-top: 100%;
}

.previewImage {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.previewDocument {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #ddd;
}

.previewDeleteButton {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 2rem;
  height: 2rem;
  z-index: 10;
  cursor: pointer;
}
