@value md-up, lg-up, xl-up from 'assets/css/variables/breakpoints.css';

.aboutUs {
  composes: contentContainer from 'assets/css/elements/layout.css';
  background-color: var(--color-black);
  color: var(--color-white);
  padding-top: var(--space-xxxl);
  padding-bottom: 0;
}

.aboutUsInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  max-width: 65rem;
}

.items {
  display: flex;
  flex-direction: column;
  align-items: center;
  counter-reset: about-counter;
}

@media (lg-up) {
  .items {
    align-items: flex-start;
  }
}

.item {
  --paddingLeft: 0;
  position: relative;
  max-width: 100%;
  width: 30rem;
  padding-left: var(--paddingLeft);
  padding-top: 4rem;
  z-index: 1;
  counter-increment: about-counter;
}

@media (md-up) {
  .item {
    --paddingLeft: 8rem;
    padding-top: 0;
    width: calc(var(--paddingLeft) + 30rem);
  }
}

@media (lg-up) {
  .item:nth-child(even) {
    margin-left: auto;
  }
}

.item:not(:last-child) {
  margin-bottom: var(--space-xxl);
}

.itemTitle {
  composes: heading1 from 'assets/css/elements/typography.css';
  margin-top: var(--space-lg);
  margin-bottom: var(--space-md);
}

.itemDescription {
  max-width: 40ch;
  font-size: var(--text-md);
}

.itemDigit {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  z-index: -1;
}

.itemDigit::before {
  content: counter(about-counter, decimal-leading-zero);
  font-size: 13rem;
  font-family: var(--font-family-title);
  position: absolute;
  left: -0.5rem;
  top: -3rem;
  letter-spacing: -0.17em;
  color: transparent;
  -webkit-text-stroke: 1px var(--color-cc-grey-tertiary);
  z-index: -1;
}

@media (md-up) {
  .itemDigit::before {
    font-size: 22.5rem;
    left: -3rem;
    top: -8.5rem;
  }
}

@media (lg-up) {
  .itemDigit::before {
    left: -1rem;
  }
}

@media (xl-up) {
  .itemDigit::before {
    left: -3rem;
  }
}

.itemPaintDot {
  position: absolute;
  top: 0;
  right: 0;
  width: 45%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}

.itemPaintDotVideo {
  position: absolute;
  left: 0;
  top: 35%;
  height: 25%;
  width: auto;
  z-index: -2;
}

@media (md-up) {
  .itemPaintDotVideo {
    top: 50%;
    height: 40%;
  }
}

.item:nth-child(1) .itemPaintDot {
  background-image: url('assets/img/about-us-paint-dot.png');
}

.item:nth-child(2) .itemPaintDot {
  background-image: url('assets/img/about-us-paint-dot2.png');
  width: 40%;
}

.itemPaintDotVideo {
  filter: contrast(2);
}

.item:nth-child(2) .itemPaintDotVideo {
  filter: contrast(2) hue-rotate(300deg);
}

/* Animation */
@media (lg-up) {
  .itemTitle,
  .itemDescription {
    transform: translateY(50px);
  }

  .itemDescription {
    animation-delay: 0.1s;
  }
}

.rollUp {
  composes: rollUp from 'assets/css/elements/animation.css';
}
