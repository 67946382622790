@value md-up, lg-up, lg-down from 'assets/css/variables/breakpoints.css';

.awardsCallToAction {
  composes: contentContainer from 'assets/css/elements/layout.css';
  padding-top: var(--space-xxl);
  padding-bottom: var(--space-xxl);
}

.awardsCallToActionInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  max-width: 80rem;
  text-align: center;
}

.title {
  composes: markdown from 'assets/css/elements/markdown.css';
  composes: heading1 from 'assets/css/elements/typography.css';
  margin-bottom: var(--space-md);
}

.highlighted:after {
  height: 150%;
  width: 150%;
  left: -15%;
}

.button {
  composes: buttonPrimary from 'assets/css/elements/form.css';
  composes: buttonWide from 'assets/css/elements/form.css';
  margin-top: var(--space-md);
}
