:root {
  /*
   * Fallback font stacks taken from:
   *
   * https://github.com/csstools/sanitize.css/blob/main/typography.css
   * https://www.cssfontstack.com/Impact
   * https://www.cssfontstack.com/Monaco
   */
  /* --font-family-title: 'Trade Gothic LT Pro Cn', Impact, Haettenschweiler,
    Franklin Gothic Bold, Charcoal, Helvetica Inserat, Bitstream Vera Sans Bold,
    Arial Black, system-ui, -apple-system, 'Segoe UI', 'Roboto', 'Ubuntu',
    'Cantarell', 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji'; */
  --font-family-title: 'Trade Gothic LT Pro Cn';

  /* --font-family-body: 'Source Code Pro', monaco, Consolas, Lucida Console,
    ui-monospace, 'Menlo', 'Consolas', 'Roboto Mono', 'Ubuntu Monospace',
    'Noto Mono', 'Oxygen Mono', 'Liberation Mono', monospace,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'; */
  --font-family-body: 'Source Code Pro';
}

@font-face {
  font-family: 'Source Code Pro';
  /* prettier-ignore */
  src: url('assets/fonts/SourceCodePro-Bold.woff2') format('woff2'),
       url('assets/fonts/SourceCodePro-Bold.woff')  format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Code Pro';
  /* prettier-ignore */
  src: url('assets/fonts/SourceCodePro-Medium.woff2') format('woff2'),
       url('assets/fonts/SourceCodePro-Medium.woff')  format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Code Pro';
  /* prettier-ignore */
  src: url('assets/fonts/SourceCodePro-Regular.woff2') format('woff2'),
       url('assets/fonts/SourceCodePro-Regular.woff')  format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Code Pro';
  /* prettier-ignore */
  src: url('assets/fonts/SourceCodePro-Semibold.woff2') format('woff2'),
       url('assets/fonts/SourceCodePro-Semibold.woff')  format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Trade Gothic LT Pro Cn';
  /* prettier-ignore */
  src: url('assets/fonts/TradeGothicLTPro-BdCn20.woff2') format('woff2'),
       url('assets/fonts/TradeGothicLTPro-BdCn20.woff')  format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
