/*
 Taken from Bootstrap:
 https://github.com/twbs/bootstrap/blob/main/scss/mixins/_visually-hidden.scss

 Hide content visually while keeping it accessible to assistive technologies

 See: https://www.a11yproject.com/posts/2013-01-11-how-to-hide-content/
 See: https://hugogiraudel.com/2016/10/13/css-hide-and-seek/
 */

.visuallyHidden {
  position: absolute !important;
  overflow: hidden !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  border: 0 !important;
  margin: -1px !important; /* Fix for https://github.com/twbs/bootstrap/issues/25686 */
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
}
