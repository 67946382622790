.pagination {
  margin-top: var(--space-lg);
}

.paginationButton {
  display: inline-block;
  background-color: var(--color-black);
  color: var(--color-white);
  padding: var(--space-xs);
  font-size: var(--text-md);
  font-weight: bold;
  text-decoration: none;
}

.paginationButton:where(:hover, :active) {
  opacity: 0.75;
}

.paginationButtonActive {
  background-color: var(--color-cc-orange);
}

.paginationButtonActive:where(:hover, :active) {
  opacity: 1;
  cursor: default;
}
