@value md-up, xl-up, md-down from 'assets/css/variables/breakpoints.css';

.entryForm {
  composes: contentContainer from 'assets/css/elements/layout.css';
  margin-top: var(--space-xxxl);
  margin-bottom: var(--space-xxl);
}

.entryFormInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  max-width: 51rem;
}

.header {
  position: relative;
  margin-bottom: 7rem;
}

.title {
  composes: heading1 from 'assets/css/elements/typography.css';
  text-align: center;
}

.subtitle {
  composes: heading2 from 'assets/css/elements/typography.css';
  margin-top: 2rem;
  text-align: center;
}

.paintDot {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  width: 100%;
  height: 19rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.paintDotStep1 {
  background-image: url('assets/img/step1.jpg');
}
.paintDotStep2 {
  background-image: url('assets/img/step2-3.jpg');
}
.paintDotStep3 {
  background-image: url('assets/img/step2-3.jpg');
}
.paintDotStep4 {
  background-image: url('assets/img/step4.jpg');
}
.group {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.groupLg {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.label {
  composes: label from 'assets/css/elements/form.css';
}
.submitButtonWrapper {
  margin-top: 4rem;
  margin-bottom: 2rem;
  text-align: center;
}
.submitButton {
  composes: buttonPrimary from 'assets/css/elements/form.css';
}
.previousStepButtonWrapper {
  text-align: center;
}
.previousStepButton {
  composes: buttonLink from 'assets/css/elements/form.css';
}

.addEntrantButton {
  composes: buttonInput from 'assets/css/elements/form.css';
  width: 100%;
}

.returnToEntrantButton {
  composes: buttonInput from 'assets/css/elements/form.css';
  background: var(--color-gray-light);
  border: 0;
  width: 100%;
}
.notes {
  margin-top: 2rem;
  text-align: left;
}
.tableWrapper {
  overflow-x: auto;
  padding-bottom: 2rem;
}
.table {
  display: flex;
  flex-wrap: wrap;
  min-width: 40rem;
}
.column {
  box-sizing: border-box;
  flex-grow: 1;
  width: 43%;
  padding: 1.5rem;
  overflow: hidden;
}

.columnSmall {
  width: 7%;
}

.columnWhite {
  background: var(--color-white);
  color: var(--color-gray);
}

.columGray {
  background: var(--color-gray-light);
  color: var(--color-black);
}

.columnHeader {
  composes: column columnWhite;
  text-transform: uppercase;
  font-weight: 600;
}

.columnHeaderIndex {
  composes: column columnSmall columnWhite;
}

.columnHeaderDeleteButton {
  composes: column columnSmall columnWhite;
}

.columnContactIndex {
  composes: column columnSmall columGray;
}

.columnContact {
  composes: column columGray;
}

.columnContactDeleteButton {
  composes: column columnSmall columGray;
}

.removeContactButton {
  border: 0;
  padding: 0;
  font-size: 1.5rem;
  line-height: 1;
}

.discountCodeForm {
  composes: contentContainer from 'assets/css/elements/layout.css';
  margin-top: var(--space-xxxl);
  margin-bottom: var(--space-xxl);
}

.discountCodeFormInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  max-width: 20rem;
}

.hiddenForm {
  display: none;
}
