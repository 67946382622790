@value md-up, lg-up, lg-down from 'assets/css/variables/breakpoints.css';

.awardsSelector {
  composes: contentContainer from 'assets/css/elements/layout.css';
  background-color: var(--color-black);
  padding-top: var(--space-xxl);
  padding-bottom: var(--space-xxl);
}

.awardsSelectorInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  max-width: 80rem;
  text-align: center;
  color: var(--color-white);
}

.title {
  composes: heading2 from 'assets/css/elements/typography.css';
  font-size: var(--text-xl-plus);
  margin-bottom: var(--space-xl);
}

.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  list-style: none;
}
.links > *:not(:last-child) {
  margin-bottom: var(--space-xl);
}
@media (md-up) {
  .links {
    flex-direction: row;
    justify-content: center;
  }
  .links > *:not(:last-child) {
    margin-bottom: 0;
    margin-right: var(--space-xxl);
  }
}
.linkItem {
  margin: 0;
}

.link {
  display: inline-block;
  font-family: var(--font-family-title);
  font-size: var(--text-xl);
  font-weight: bold;
  text-transform: uppercase;
  max-width: 25ch;
  color: var(--color-white);
  text-decoration: none;
}

/* Show first active if not hovered any, order of styles important */
.linkItem:nth-child(1) a {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('assets/svg/highlight-small.svg');
}
.links:hover a {
  background-image: none;
}
.linkItem .link:is(.active, :hover, :focus) {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('assets/svg/highlight-small.svg');
}
