@value md-down, md-up, lg-up, lg-down, xxl-down from 'assets/css/variables/breakpoints.css';

.navbar {
  composes: contentContainer from 'assets/css/elements/layout.css';
  padding-left: 1rem;
  padding-right: 1rem;
  position: absolute;
  top: 0;
  z-index: 19;
}

@media (lg-up) {
  .navbar {
    padding-left: 5rem;
  }
}

.navbarInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  max-width: 90rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: var(--space-lg);
}

@media (md-up) {
  .navbarInner {
    width: 100%;
  }
}

.logoWrapper {
  width: 19rem;
  margin-left: -6rem;
}

@media (lg-down) {
  .logoWrapper {
    width: 12rem;
    margin-left: -2rem;
  }
}

.menuButton {
  margin-right: 1rem;
  color: var(--color-white);
}

.menuButtonPurple {
  color: var(--color-cc-magenta);
}

.menuButtonHide {
  display: none;
}

.menuButtonWhite {
  color: white;
}

.navLinksWrapper {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  padding: var(--space-lg);
  background-color: var(--color-black);
  overscroll-behavior-y: contain;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-100%);
  transition: all 0.2s ease-in-out;
}

@media (lg-up) {
  .navLinksWrapper {
    transform: translateX(100%);
    width: 25rem;
  }
}

.navLinksWrapperShow {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

@media (lg-up) {
  .navLinksWrapperShow {
    transform: translateX(0);
  }
}

.navItems {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.closeButton {
  display: block;
  width: 2rem;
  height: 2rem;
  color: var(--color-white);
  cursor: pointer;
  margin-left: auto;
  margin-bottom: var(--space-md);
}

.navbarLinkItem:not(:last-child) {
  margin-bottom: var(--space-sm);
}

.navbarLinkItemLink {
  composes: heading2 from 'assets/css/elements/typography.css';
  text-transform: none;
  color: var(--color-white);
  display: block;
  position: relative;
  padding: 1rem 0 1rem 2rem;
  z-index: 1;
}

.navbarLinkItemLink::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('assets/img/navbar-paint.png');
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0;
  transition: 0.2s;
  z-index: -1;
  clip-path: polygon(0 0, 0% 0%, 0% 100%, 0% 100%);
}

.navbarLinkItemLink:focus {
  outline: none;
}

.navbarLinkItemLink:where(:hover, :focus)::before,
.navItems:not(:hover) .navbarLinkItemLinkActive::before {
  opacity: 1;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
}
