:root {
  font-family: var(--font-family-body);
}

html,
body {
  padding: 0;
  margin: 0;
  line-height: 1.5;
}

a {
  color: inherit;
}

noscript {
  display: block;
  text-align: center;
}

* {
  box-sizing: border-box;
}

.visually-hidden {
  position: absolute !important;
  overflow: hidden !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  border: 0 !important;
  margin: -1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
}

button {
  border: 0;
  background: transparent;
  cursor: pointer;
  padding: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

input:focus,
:focus:not(:focus-visible),
textarea:focus {
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

svg {
  pointer-events: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

iframe {
  border: 0;
}

/*
 * Reduced motion accessibility
 *
 * https://github.com/csstools/sanitize.css/blob/main/reduce-motion.css
 *
 * 1. Remove animations when motion is reduced (opinionated).
 * 2. Remove fixed background attachments when motion is reduced (opinionated).
 * 3. Remove timed scrolling behaviors when motion is reduced (opinionated).
 * 4. Remove transitions when motion is reduced (opinionated).
 */
@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation-delay: -1ms !important; /* 1 */
    animation-duration: 1ms !important; /* 1 */
    animation-iteration-count: 1 !important; /* 1 */
    background-attachment: initial !important; /* 2 */
    scroll-behavior: auto !important; /* 3 */
    transition-delay: 0s !important; /* 4 */
    transition-duration: 0s !important; /* 4 */
  }
}
