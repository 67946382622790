@value md-up, lg-up, lg-down from 'assets/css/variables/breakpoints.css';

.awardsIntro {
  composes: contentContainer from 'assets/css/elements/layout.css';
}

.awardsIntroInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  max-width: 60rem;
}

.stage {
  display: flex;
  flex-direction: row;
}

@media (lg-down) {
  .stage {
    max-width: 28rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.stageCopy {
  margin-bottom: 5.5rem;
  padding-top: var(--space-xxxl);
}

@media (lg-up) {
  .stageCopy {
    width: 50%;
    margin-bottom: 16rem;
  }
}

.stageTimeline {
  position: relative;
  flex-grow: 1;
  text-align: center;
}

@media (lg-down) {
  .stageTimeline {
    display: none;
  }
}

.stageDeadline {
  width: 20%;
}

@media (lg-down) {
  .stageDeadline {
    display: none;
  }
}

.title {
  composes: heading1 from 'assets/css/elements/typography.css';
  margin-bottom: var(--space-md);
}

.copy {
  composes: markdown from 'assets/css/elements/markdown.css';
}

.line {
  display: inline-block;
  background-color: var(--color-cc-red);
  width: 5px;
  height: 100%;
}
