.highlighted {
  display: inline-block;
  position: relative;
}

.highlighted:after {
  content: '';
  position: absolute;
  background-size: 100% auto;
  background-repeat: no-repeat;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 130%;
}

.highlighted.small:after {
  background-image: url('assets/svg/highlight-small.svg');
}

.highlighted.medium:after {
  background-image: url('assets/svg/highlight-medium.svg');
}

.highlighted.large:after {
  background-image: url('assets/svg/highlight-medium2.svg');
}

.highlighted.xlarge:after {
  background-image: url('assets/svg/highlight-medium3.svg');
}
