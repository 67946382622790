@value md-up, xl-up, md-down from 'assets/css/variables/breakpoints.css';

.enterDetails {
  composes: contentContainer from 'assets/css/elements/layout.css';
  margin-top: var(--space-xxxl);
  margin-bottom: var(--space-xxl);
}

.enterDetailsInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
  max-width: 72rem;
}

.title {
  composes: heading1 from 'assets/css/elements/typography.css';
  margin-bottom: var(--space-xxl);
  max-width: 70ch;
}

@media (md-up) {
  .grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
  }
}

.textBlocks {
  grid-column: 1/7;
  grid-row: 1;
}

.paymentSelector {
  grid-column: 7/-1;
  grid-row: 1;
}

@media (md-down) {
  .paymentSelector {
    margin-top: var(--space-xl);
  }
}

@media (md-up) {
  .sticky {
    position: sticky;
    top: var(--space-lg);
    max-width: 390px;
    margin-left: auto;
    padding-left: 1rem;
  }
}

.textBlock {
  position: relative;
}

.textBlock:not(:last-child) {
  margin-bottom: var(--space-xxl);
}

.textBlockImage {
  position: absolute;
  right: 0;
  top: -2rem;
  z-index: -1;
}

.textBlockTitle {
  composes: heading1 from 'assets/css/elements/typography.css';
  margin-bottom: var(--space-md);
}

.label {
  composes: label from 'assets/css/elements/form.css';
}

.quantityInput {
  composes: input from 'assets/css/elements/form.css';
}

.description {
  composes: group from 'assets/css/elements/form.css';
}

.inputGroup {
  composes: group from 'assets/css/elements/form.css';
}

.applyButtonGroup {
  margin-top: 3rem;
}

.applyButton {
  composes: buttonPrimary from 'assets/css/elements/form.css';
}
