.cookieNotification {
  --width: var(--cn-width, 300px);
  --max-width: var(--cn-max-width, 90%);
  --background-color: var(--cn-background-color, black);
  --color: var(--cn-color, white);
  --padding: var(--cn-padding, 2rem);
  --top: var(--cn-top, auto);
  --left: var(--cn-left, auto);
  --bottom: var(--cn-bottom, 2rem);
  --right: var(--cn-right, 2rem);
  --translate: var(--cn-translate, none);
  --font-family: var(--cn-font-family, inherit);
  --font-size: var(--cn-font-size, 1rem);
  --buttons-wrapper-margin-top: var(--cn-buttons-wrapper-margin-top, 1.25rem);
  --buttons-wrapper-flex-direction: var(
    --cn-buttons-wrapper-flex-direction,
    row
  );
  --buttons-wrapper-justify-content: var(
    --cn-buttons-wrapper-justify-content,
    space-between
  );
  --button-width: var(--cn-button-width, auto);
  --button-padding: var(--cn-button-padding, 0.25rem 2rem);
  --button-border-radius: var(--cn-button-border-radius, 0);
  --button-color: var(--cn-button-color, var(--cn-color));
  --agree-button-margin-bottom: var(--cn-agree-button-margin-bottom, 0);
  --agree-button-border: var(--cn-agree-button-border, 1px solid currentColor);
  --agree-button-font-size: var(--cn-agree-button-font-size, 1em);
  --refuse-button-border: var(
    --cn-refuse-button-border,
    1px solid currentColor
  );
  --refuse-button-font-size: var(--cn-refuse-button-font-size, 0.8em);

  position: fixed;
  top: var(--top);
  left: var(--left);
  bottom: var(--bottom);
  right: var(--right);
  width: var(--width);
  max-width: var(--max-width);
  padding: var(--padding);
  background-color: var(--background-color);
  color: var(--color);
  transform: var(--translate);
  font-size: var(--text-size);
  z-index: 10000000000;
}

.buttonsWrapper {
  display: flex;
  flex-direction: var(--buttons-wrapper-flex-direction);
  justify-content: var(--buttons-wrapper-justify-content);
  margin-top: var(--buttons-wrapper-margin-top);
}

.button {
  width: var(--button-width);
  padding: var(--button-padding);
  border-radius: var(--button-border-radius);
  color: var(--button-color);
  cursor: pointer;
}

.buttonAgree {
  composes: button;
  border: var(--agree-button-border);
  margin-bottom: var(--agree-button-margin-bottom);
  font-size: var(--agree-button-font-size);
  text-transform: uppercase;
}

.buttonRefuse {
  composes: button;
  border: var(--refuse-button-border);
  font-size: var(--refuse-button-font-size);
}
