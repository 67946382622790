@value sm-down, sm-up, md-up, lg-up, xl-up from 'assets/css/variables/breakpoints.css';

.peopleGrid {
  composes: contentContainer from 'assets/css/elements/layout.css';
  padding-top: var(--space-xxl);
  padding-bottom: var(--space-xxl);
  background-color: var(--color-black);
  color: var(--color-white);
  overflow: hidden;
}

@media (sm-down) {
  .peopleGrid {
    padding-left: 0;
    padding-right: 0;
  }
}

.peopleGridInner {
  composes: contentContainerInner from 'assets/css/elements/layout.css';
}

.heading {
  composes: heading1 from 'assets/css/elements/typography.css';
  margin-bottom: var(--space-xxl);
}

.grid {
  display: grid;
  gap: 15%;
  padding: 2rem 10%;
}

@media (sm-down) {
  .grid {
    display: block;
  }
  .grid > *:not(:last-child) {
    margin-bottom: 4rem;
  }
}

@media (sm-up) {
  .grid {
    gap: 3rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 0 1rem;
  }
}

@media (md-up) {
  .grid {
    gap: 4rem;
  }
}

@media (lg-up) {
  .grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (xl-up) {
  .grid {
    gap: 6rem;
    padding: 0;
  }
}

.person {
  text-align: center;
}

@media (sm-down) {
  .person {
    width: 100%;
    text-align: center;
  }
}

/* Demo */
.personActive {
  text-align: center; /* Temporary fix to stop typescript complaining */
}

.personLink {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.imageWrapper {
  display: block;
  width: 85%;
  height: 0;
  padding-bottom: 85%;
  position: relative;
  margin-bottom: 2rem;
}

@media (sm-down) {
  .imageWrapper {
    width: 60%;
    padding-bottom: 60%;
  }
}

.imageWrapper::after {
  content: '';
  display: block;
  position: absolute;
  top: -6px;
  right: -6px;
  bottom: -6px;
  left: -6px;
  background-color: var(--color-cc-magenta);
  border-radius: 50%;
  opacity: 0;
  transition: transform 0.1s ease-out, opacity 0.2s ease;
  pointer-events: none;
  z-index: -1;
}

.personLink:focus {
  outline: none;
}

.personLink:where(:hover, :focus) .imageWrapper::after {
  opacity: 1;
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
}

.personName {
  composes: heading2 from 'assets/css/elements/typography.css';
  margin-top: auto;
}

.personRole {
  composes: heading4Sub from 'assets/css/elements/typography.css';
  margin-top: 0.5rem;
  margin-bottom: auto;
}
